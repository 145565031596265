<template>
  <div class="row">
    <div class="col">
      <div v-if="warning" class="text-subtitle1 q-pa-sm row items-center">
        <q-icon
          name="error_outline"
          color="warning"
          size="3rem"
        />

        <div class="col q-px-sm">
          {{ warning }}
        </div>
      </div>

      <slot v-if="type === 'international'" />

      <form-builder :schema="schema" />

      <slot v-if="type === 'domestic'" />
    </div>

  </div>
</template>

<script>
import _ from 'lodash'

// Helpers

// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components


export default {
  emits: ['address'],
  props: {
    isLoading: {
      type: Boolean,
      default () {
        return false
      }
    },
    notRequired: {
      type: Boolean,
      default () {
        return false
      }
    },
    warning: {
      type: String,
      default () {
        return null
      }
    },
    type: {
      type: String,
      default () {
        return 'international'
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default () {
        return {
          extId: null,
          postcode: null,
          quarter: null,
          streetPrefix: null,
          street: null,
          house: null,
          block: null,
          apartment: null,
          notFormal: null,
          geo: [],
          raw: [],
          floor: null,
          entrance: null,
          id: null,
          state: 'active',
          _embedded: {
            locality: {}
          }
        }
      }
    }
  },
  data () {
    return {
      address: _.cloneDeep(this.model),
      currentPlace: null,
      localities: [],
      localitiesPage: 1,
      localitiesTotalPage: 1,
      lastLocalitySearch: ''
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    schema () {
      if (this.type === 'domestic') {
        return {
          isLoading: this.isLoading,
          groups: [
            {
              styleClasses: 'row',
              fields: [
                {
                  type: 'input',
                  inputType: 'text',
                  field: 'street',
                  value: this.address.street,
                  disabled: this.disabled,
                  required: !this.notRequired,
                  label: this.$t('Address Line 1'),
                  hint: this.$t('Street address, P.O. box, company name, c/o'),
                  wrapperStyleClasses: 'col-12 q-pa-xs',
                  onChange: street => {
                    this.$emit('address', { ...this.address, street })
                  }
                },
                {
                  type: 'input',
                  inputType: 'text',
                  label: this.$t('Address Line 2'),
                  field: 'house',
                  value: this.address.house,
                  hint: this.$t('Apartment, suite, unit, building, floor, etc'),
                  disabled: this.disabled,
                  wrapperStyleClasses: 'col-12 q-pa-xs',
                  onChange: (house) => {
                    this.$emit('address', { ...this.address, house })
                  }
                }
                // {
                //   type: 'address',
                //   label: this.$t('Address Line 2'),
                //   field: 'notFormal',
                //   value: this.address.notFormal,
                //   hint: this.$t('Apartment, suite, unit, building, floor, etc'),
                //   disabled: this.disabled,
                //   wrapperStyleClasses: this.appOptions.googleApiKey
                //     ? 'col-12 q-pa-xs'
                //     : 'd-none',
                //   googleApiKey: this.appOptions.googleApiKey,
                //   onInput: (notFormal) => {
                //     this.$emit('address', { ...this.address, notFormal })
                //   },
                //   onPlaceChanged: (value, place) => {
                //     this.currentPlace = place
                //     this.addMarker()

                //     let localAddress = {
                //       quarter: place.neighborhood,
                //       postcode: place.postal_code,
                //       notFormal: this.address.notFormal,
                //       _embedded: {
                //         locality: this.address._embedded.locality
                //       }
                //     }

                //     if (place.route) {
                //       localAddress.street = place.route
                //     }

                //     if (place.street_number) {
                //       localAddress.house = place.street_number
                //     }

                //     localAddress.geo = {
                //       latitude: value.y,
                //       longitude: value.x
                //     }

                //     if (!place.postal_code) {
                //       this.address = localAddress
                //       this.$emit('address', this.address)
                //       return Promise.resolve(localAddress)
                //     }

                //     localAddress.postcode = place.postal_code

                //     return this.loadLocalitiesByPostcode(localAddress.postcode)
                //       .then(locality => {
                //         localAddress._embedded.locality = locality
                //         this.$emit('address', localAddress)
                //         return localAddress
                //       })
                //   }
                // }
              ]
            }
          ]
        }
      }
      return {
        isLoading: this.isLoading,
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                field: 'street',
                required: !this.notRequired,
                value: this.address.street,
                disabled: this.disabled,
                label: this.$t('Street'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: street => {
                  this.$emit('address', { ...this.address, street })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                field: 'house',
                disabled: this.disabled,
                label: this.$t('House'),
                wrapperStyleClasses: 'col-4 q-pa-xs',
                value: this.address.house,
                onChange: house => {
                  this.$emit('address', { ...this.address, house })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                field: 'quarter',
                disabled: this.disabled,
                label: this.$t('Quarter'),
                wrapperStyleClasses: 'col-4 q-pa-xs',
                value: this.address.quarter,
                onChange: quarter => {
                  this.$emit('address', { ...this.address, quarter })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                field: 'block',
                disabled: this.disabled,
                label: this.$t('Block'),
                wrapperStyleClasses: 'col-4 q-pa-xs',
                value: this.address.block,
                onChange: block => {
                  this.$emit('address', { ...this.address, block })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                field: 'entrance',
                disabled: this.disabled,
                label: this.$t('Entrance'),
                wrapperStyleClasses: 'col-4 q-pa-xs',
                value: this.address.entrance,
                onChange: entrance => {
                  this.$emit('address', { ...this.address, entrance })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                field: 'floor',
                disabled: this.disabled,
                label: this.$t('Floor'),
                wrapperStyleClasses: 'col-4 q-pa-xs',
                value: this.address.floor,
                onChange: floor => {
                  this.$emit('address', { ...this.address, floor })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                field: 'apartment',
                disabled: this.disabled,
                label: this.$t('Apartment'),
                wrapperStyleClasses: 'col-4 q-pa-xs',
                value: this.address.apartment,
                onChange: apartment => {
                  this.$emit('address', { ...this.address, apartment })
                }
              },
              {
                type: 'address',
                label: this.$t('Free-form address'),
                field: 'notFormal',
                value: this.address.notFormal,
                disabled: this.disabled,
                wrapperStyleClasses: this.appOptions.googleApiKey
                  ? 'col-12 q-pa-xs'
                  : 'd-none',
                googleApiKey: this.appOptions.googleApiKey,
                onInput: (notFormal) => {
                  this.$emit('address', { ...this.address, notFormal })
                },
                onPlaceChanged: (value, place) => {
                  this.currentPlace = place
                  this.addMarker()

                  let localAddress = {
                    quarter: place.neighborhood,
                    postcode: place.postal_code,
                    notFormal: this.address.notFormal,
                    _embedded: {
                      locality: this.address._embedded.locality
                    }
                  }

                  if (place.route) {
                    localAddress.street = place.route
                  }

                  if (place.street_number) {
                    localAddress.house = place.street_number
                  }

                  localAddress.geo = {
                    latitude: value.y,
                    longitude: value.x
                  }

                  if (!place.postal_code) {
                    this.address = localAddress
                    this.$emit('address', this.address)
                    return Promise.resolve(localAddress)
                  }

                  localAddress.postcode = place.postal_code

                  return this.loadLocalitiesByPostcode(localAddress.postcode)
                    .then(locality => {
                      localAddress._embedded.locality = locality
                      this.$emit('address', localAddress)
                      return localAddress
                    })
                }
              }
            ]
          }
        ]
      }
    }
  },
  watch: {
    model: {
      handler (newValue) {
        this.address = _.cloneDeep(newValue)
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations([
      'addWarningNotification'
    ]),
    loadLocalitiesByPostcode (value) {
      if (value && value.length < 4) {
        this.addWarningNotification('Postcode too short')
        return
      }

      const query = {
        page: 1,
        per_page: 25,
        filter: [
          { field: 'extId', type: 'eq', value }
        ]
      }

      return this.$service.postcode.getAll(query)
        .then(({ items }) => {
          if (items.length === 0) {
            this.addWarningNotification('No localities found!')
            return
          }

          this.localities = items.reduce((acc, item) => {
            const locality = item._embedded.locality
            if (!acc.find(x => x.id === locality.id)) {
              acc.push(locality)
            }

            return acc
          }, [])

          if (this.localities.length === 1) {
            return this.localities[0]
          }
        })
    },
  }
}
</script>

<style>
.form-group .field-wrap .wrapper {
  padding: 0;
}
</style>
